import Footer from "../components/Footer/Footer";
import styled from "styled-components";

const Container = styled.div``;

const Footersection = () => {
    return (
        <Container>
            <Footer />
        </Container>
    );
};

export default Footersection;